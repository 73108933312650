export default function setupLazyLoad(): void {
  document.addEventListener(
    "scroll",
    function () {
      const element = document.querySelector("video.lazy")
      if (!element) return

      if ("IntersectionObserver" in window) {
        const lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
          const video = entries[0]

          if (video.isIntersecting) {
            for (const source in video.target.children) {
              const videoSource = video.target.children[source]
              if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                videoSource.src = videoSource.dataset.src
              }
            }

            video.target.load()
            video.target.classList.remove("lazy")
            lazyVideoObserver.unobserve(video.target)
          }
        })

        lazyVideoObserver.observe(element)
      }
    },
    { once: true }
  )
}
