export function actionLabel(applet, user) {
  return applet.author === user.login
    ? {
        label: "Archive",
        past: "Archived",
        infinitive: "Archiving",
      }
    : {
        label: "Delete",
        past: "Deleted",
        infinitive: "Deleting",
      }
}

export function ctaCopyToConnect(shortName) {
  return shortName ? `Connect ${shortName}` : "Connect"
}
