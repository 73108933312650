import { loadState, saveState } from "shared/localstorage_helper"
import { Permissions } from "types"

const newBadgeDataAttribute = "data-allow-new-badge"

const newBadgeStorageId = () => {
  return `newBadgeForSubscribers-${window.App.user?.id}`
}

const BADGE_EXPIRATION_IN_DAYS = 7
const BADGE_EXPIRATION_DATE = new Date().setDate(new Date().getDate() + BADGE_EXPIRATION_IN_DAYS)

export function saveUserPermissions(tier: string, permissions: Permissions): void {
  const storage = loadState(newBadgeStorageId())

  const upgradePermissions = {
    ttl: BADGE_EXPIRATION_DATE,
    header_create_cta: true,
    filter_code: tier === "pro" && permissions["filter_code"]?.permitted,
    queries: tier === "pro" && permissions["queries"]?.permitted,
    multi_service_account: tier === "pro" && permissions["multi_service_account"]?.permitted,
    multi_action: permissions["multi_action"]?.permitted,
  }

  if (!storage) {
    saveState({ ...upgradePermissions }, newBadgeStorageId())
  } else {
    saveState({ ...storage, ...upgradePermissions }, newBadgeStorageId())
  }

  attachNewBadgeForButtons()
}

function removeAllBadges(storage: { [key: string]: any }): void {
  Object.entries(storage).forEach(([key, value]) => {
    if (value === true) {
      storage[key] = false
    }
  })

  saveState({ ...storage }, newBadgeStorageId())
}

export function attachNewBadgeForButtons(): void {
  const storage = loadState(newBadgeStorageId())

  if (!storage) return

  if (new Date().getTime() > storage.ttl) {
    removeAllBadges(storage)
    return
  }

  const elementsToShow = Object.entries(storage)
    .filter(([_key, value]) => value === true)
    .map(([k, _v]) => k)

  if (elementsToShow.length === 0) {
    return
  } else if (elementsToShow.length === 1 && elementsToShow[0] === "header_create_cta") {
    // Some users could start flow inside DIY and accomplish features there just reamining create button from header. In this case, remove it.
    saveState({ ...storage, header_create_cta: false }, newBadgeStorageId())
    attachNewBadgeForButtons()
  } else if (
    elementsToShow.length === 2 &&
    elementsToShow.includes("header_create_cta") &&
    elementsToShow.includes("multi_service_account")
  ) {
    // Multi service account is too deep to show create button badge. It's an exception.
    saveState({ ...storage, header_create_cta: false }, newBadgeStorageId())
    attachNewBadgeForButtons()
  } else if (elementsToShow) {
    document.querySelectorAll(`[${newBadgeDataAttribute}]`).forEach(button => {
      const permission: string | null = button.getAttribute(newBadgeDataAttribute)

      if (!permission) return

      const intermediaryButton =
        permission === "filter_code_queries_delay" && (storage["queries"] || storage["filter_code"])

      if (button.classList.contains("button-with-badge-new")) return

      if (storage[permission] || intermediaryButton) {
        button.classList.add("button-with-badge-new")

        // we should maintain header_create_cta as true until user explore all options. Not add
        if (permission === "header_create_cta") return

        button.addEventListener(
          "click",
          () => {
            saveState({ ...storage, [permission]: false }, newBadgeStorageId())
          },
          { once: true }
        )
      }
    })
  }
}

export function attachNewBadgeForMultiServiceAccountButton(): void {
  const storage = loadState(newBadgeStorageId())

  return storage && storage["multi_service_account"]
}

export function removeMultiServiceAccountNewBadge(): void {
  const storage = loadState(newBadgeStorageId())

  saveState({ ...storage, multi_service_account: false }, newBadgeStorageId())
}

function setBadgeExpirationDate(): void {
  const storage = loadState(newBadgeStorageId())

  if (!storage) {
    saveState({ ttl: BADGE_EXPIRATION_DATE }, newBadgeStorageId())
    return
  }

  storage["ttl"] = BADGE_EXPIRATION_DATE
  saveState({ ...storage }, newBadgeStorageId())
}

export function updatePermissionInStorage(permission: string, value: boolean): void {
  const storage = loadState(newBadgeStorageId())

  if (!storage) return
  if (!(permission in storage)) return

  storage[permission] = value
  saveState({ ...storage }, newBadgeStorageId())
}

export function addNewPermission(permissions: string[]): void {
  if (permissions.length === 0) return

  setBadgeExpirationDate()
  const storage = loadState(newBadgeStorageId())

  if (!storage) return

  permissions.forEach(permission => {
    if (storage[permission]) return

    storage[permission] = true
    saveState({ ...storage }, newBadgeStorageId())
  })
}

export function checkPermission(permission: string): boolean {
  const storage = loadState(newBadgeStorageId())

  if (!storage) {
    return false
  }

  return storage[permission]
}
