import { useState } from "react"

export const loadState = stateId => {
  try {
    const serializedState = localStorage.getItem(stateId)
    if (serializedState === null) {
      return undefined
    }

    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = (state, stateId) => {
  try {
    const serializedState = JSON.stringify(state)

    localStorage.setItem(stateId, serializedState)
  } catch (err) {
    return undefined
  }
}

export const clearState = stateId => {
  try {
    localStorage.removeItem(stateId)
  } catch (err) {
    return undefined
  }
}

export function useLocalStorage(stateId, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    const item = loadState(stateId)
    if (item === undefined) return initialValue

    return item
  })

  const setValue = value => {
    const valueToStore = value instanceof Function ? value(storedValue) : value
    setStoredValue(valueToStore)
    saveState(valueToStore, stateId)
  }

  return [storedValue, setValue]
}
