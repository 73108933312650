import ServiceCard from "./"
import { Service } from "types"

const ServiceCardList = ({ services }: { services: Service[] }) => (
  <ul className="service-card-list">
    {services.map(service => (
      <li className="service-card-list-item" data-service-id={service.id} key={service.id}>
        <ServiceCard service={service} path={`/${service.module_name}`} lazyload />
      </li>
    ))}
  </ul>
)

export default ServiceCardList
