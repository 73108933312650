import hexAndRgba from "hex-and-rgba"

export const getOverlayColor = color => {
  const rgbaColor = hexAndRgba.hexToRgba(formatHexColorValue(color))
  // This should be a range of dark colors
  // For now stick with #FFFFFF
  if (rgbaColor.toString() === "0,0,0,1") {
    return lightOverlayColor
  } else {
    return darkOverlayColor
  }
}

// Also defined in assets/stylesheets/foundation/_colors.scss
const lightOverlayColor = "rgba(255, 255, 255, 0.2)"
const darkOverlayColor = "rgba(0, 0, 0, 0.2)"

export const formatHexColorValue = value =>
  value.indexOf("#") !== -1 ? value : `#${value}`

// It's the same heuristic from ruby gem to work with lightness:
// https://github.com/plashchynski/rgb/blob/master/lib/rgb/color.rb#L25-L29
const colorLightness = color => {
  const rgba = hexAndRgba.hexToRgba(formatHexColorValue(color))

  let r = rgba[0] / 255
  let g = rgba[1] / 255
  let b = rgba[2] / 255
  let max = Math.max(r, g, b)
  let min = Math.min(r, g, b)

  return (min + max) / 2
}

export const shouldUseAlternateBrandColor = value => {
  return colorLightness(value) < 0.15
}
