import SmallAppletCard from "./small_applet_card"

const AppletCardList = ({ applets, appletPath }) => (
  <ul className="web-applet-cards">
    {applets.map(applet => (
      <li className="my-web-applet-card web-applet-card" data-applet-id={applet.id} key={applet.id}>
        <SmallAppletCard applet={applet} appletPath={`${appletPath}/${applet.id}`} />
      </li>
    ))}
  </ul>
)

export default AppletCardList
