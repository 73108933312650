import { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import XIcon from "shared/components/icons/x_icon"
import parse from "html-react-parser"

export default function UnifiedProfileDropdown({ email, avatar, fillColor, items, serviceOfflineWarning }) {
  const [opened, setOpened] = useState(false)
  const menuRef = useRef()

  useEffect(() => {
    const mobileView = window.visualViewport?.width < 724
    let clickOutside

    if (!mobileView) {
      clickOutside = e => {
        if (opened && !menuRef.current.contains(e.target)) {
          setOpened(false)
        }
      }
    }

    if (opened) {
      clickOutside && document.addEventListener("click", clickOutside)
      document.body.classList.add("mobile-account-menu-opened")
    }

    return () => {
      clickOutside && document.removeEventListener("click", clickOutside)
      document.body.classList.remove("mobile-account-menu-opened")
    }
  }, [opened])

  const openMenu = () => {
    if (opened) return

    // we need to do this before changing 'opened' to prevent a visual glitch on
    // mobile
    document.body.classList.add("mobile-account-menu-opened")

    setOpened(true)
  }

  const closeMenu = () => {
    setOpened(false)
  }

  const menuClasses = classnames("account-menu-v2", "menu-v2", { open: opened })

  return (
    <>
      <ul className={menuClasses} ref={menuRef}>
        <li className="name">
          <div
            className={classnames("profile-avatar-container", { offline: serviceOfflineWarning })}
            role="button"
            style={{ backgroundImage: `url('${avatar}')`, borderColor: fillColor, zIndex: 1000 }}
            aria-label="Profile image"
            onClick={opened ? closeMenu : openMenu}
          />
        </li>
        {opened && (
          <li>
            <ul className="submenu">
              <li className="name">
                <a title={email} className="login item">
                  <span className={classnames({ "long-email": email.length > 18 })}>{email}</span>
                  <div
                    className="profile-avatar-container"
                    style={{
                      backgroundImage: `url('${avatar}')`,
                      zIndex: 1000,
                    }}
                  />
                </a>
              </li>
              {items.map((item, i) => {
                const classes = classnames("item link-menu", item.class_name, {
                  inactionable: !item.url || item.url === "#",
                })
                return (
                  <li key={i}>
                    <a href={item.url} title={item.title_no_tags || item.title} className={classes}>
                      {parse(item.title)}
                    </a>
                  </li>
                )
              })}
            </ul>
          </li>
        )}
      </ul>
      {opened && (
        <span className="x-icon" role="button" onClick={closeMenu}>
          <XIcon width="24" height="25" color={fillColor} stroke-width="2.5" />
        </span>
      )}
    </>
  )
}

UnifiedProfileDropdown.propTypes = {
  email: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  fillColor: PropTypes.oneOf(["white", "#222"]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      title_no_tags: PropTypes.string,
      url: PropTypes.string.isRequired,
      fill_color: PropTypes.string,
    })
  ).isRequired,
  serviceOfflineWarning: PropTypes.bool.isRequired,
}
