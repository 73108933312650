export default async function ProsumerHome(opts = {}) {
  const { gsap } = await import("gsap")
  const ScrollTrigger = await import("gsap/ScrollTrigger")

  // Animation
  gsap.registerPlugin(ScrollTrigger)

  gsap.config({
    force3D: "auto",
    autoSleep: 60,
  })

  // Plan type toggle
  const planToggle = document.querySelector(".toggle")
  const prices = opts.prices
  const urls = opts.urls
  planToggle?.addEventListener("click", function (e) {
    e.preventDefault()
    this.classList.toggle("active")

    const proPlanLinks = document.querySelectorAll("a[data-plan-page='true']")
    const proPriceDisplay = document.querySelector(".pro-plus.price-amount")
    const proYearlyPriceDisplay = document.querySelector(".pro-plus.yearly-price")

    const intermediatePlanLinks = document.querySelectorAll("a[data-intermediate-plan-page='true']")
    const intermediatePriceDisplay = document.querySelector(".intermediate-pro.price-amount")
    const intermediateYearlyPriceDisplay = document.querySelector(".intermediate-pro.yearly-price")

    const interval = this.classList.contains("active") ? "yearly" : "monthly"

    proPlanLinks.forEach(link => {
      link.setAttribute("href", urls.pro_plus[interval])
    })
    proPriceDisplay.innerHTML = prices.pro_plus[interval]
    intermediatePlanLinks.forEach(link => {
      link.setAttribute("href", urls.intermediate_pro[interval])
    })

    intermediatePriceDisplay.innerHTML = prices.intermediate_pro[interval]

    proYearlyPriceDisplay.classList.toggle("hidden")
    intermediateYearlyPriceDisplay.classList.toggle("hidden")
    document.querySelectorAll(".yearly-price-spacer").forEach(el => {
      el.classList.toggle("hidden")
    })
  })

  // Animation: Logos
  const gridLogos = document.querySelector(".holder--grid--logos")
  if (gridLogos) {
    const tlLogos = gsap.timeline({
      scrollTrigger: {
        trigger: ".holder--grid--logos",
        start: "top center",
      },
    })
    tlLogos.from(".grid--logo", {
      duration: 0.5,
      opacity: "0",
      y: "50",
      stagger: {
        amount: 1.25,
      },
    })
  }

  // Animation: Connect
  const gridConnect = document.querySelector(".grid--connect__image-1")
  if (gridConnect) {
    const tlConnect1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".grid--connect__image-1",
        start: "top 1000px center",
      },
    })
    tlConnect1.from(".grid--connect__image-1", {
      duration: 0.5,
      y: "100",
      opacity: 0,
    })
    tlConnect1.from(
      ".grid--connect__copy-1",
      {
        duration: 0.5,
        y: "100",
        opacity: 0,
      },
      "-=0.25"
    )

    const tlConnect2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".grid--connect__image-2",
        start: "top 1000px center",
      },
    })
    tlConnect2.from(".grid--connect__image-2", {
      duration: 0.5,
      y: "100",
      opacity: 0,
    })
    tlConnect2.from(
      ".grid--connect__copy-2",
      {
        duration: 0.5,
        y: "100",
        opacity: 0,
      },
      "-=0.25"
    )

    const tlConnect3 = gsap.timeline({
      scrollTrigger: {
        trigger: ".grid--connect__image-3",
        start: "top 1000px center",
      },
    })
    tlConnect3.from(".grid--connect__image-3", {
      duration: 0.5,
      y: "100",
      opacity: 0,
    })
    tlConnect3.from(
      ".grid--connect__copy-3",
      {
        duration: 0.5,
        y: "100",
        opacity: 0,
      },
      "-=0.25"
    )
  }

  // Animation: home pro
  const homePro = document.querySelector(".home--pro")
  if (homePro) {
    const tlPro = gsap.timeline({
      scrollTrigger: {
        trigger: ".home--pro",
        start: "top 500px center",
      },
    })
    tlPro.from(".home--pro--bg", {
      duration: 0.5,
      x: "-100",
    })
  }

  // Animation: Quotes
  const gridQuotes = document.querySelector(".grid--quad--quotes")
  if (gridQuotes) {
    const tlQuotes = gsap.timeline({
      scrollTrigger: {
        trigger: ".grid--quad--quotes",
        start: "top 700px center",
      },
    })
    tlQuotes.from(".grid--quad--quotes li", {
      duration: 0.5,
      opacity: "0",
      y: "50",
      stagger: {
        amount: 0.25,
      },
    })
  }
}
